<template>
    <div>
        <div v-show="!NoleftArrow">
            <van-nav-bar :title="title" left-arrow @click-left="_onBackTap" fixed z-index="99" placeholder />
        </div>
        <div v-show="NoleftArrow" style="height: 1.33333rem;">
            <van-nav-bar :title="title" fixed z-index="99" placeholder />
        </div>
    </div>
</template>

<script>
    import {NavBar} from 'vant';
    import {
        checkAndroid,
        checkIOS,
    } from "../../../libs/rongxunUtil";

    //调用android关闭页面方法*******begin*********
    function backToAndroid(){
        window.himi.closeWindow();
    }
    //调用android关闭页面方法*******end***********

    /********判断H5页面是否在web容器中 start*********/
    function openInWebview () {/* 返回true或false; */
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
            return false;
        } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
            return false;
        } else if (ua.match(/WeiBo/i) == "weibo") {
            return false;
        } else {
            if (ua.match(/Android/i) != null) {
                return ua.match(/browser/i) == null;
            } else if (ua.match(/iPhone/i) != null) {
                return ua.match(/safari/i) == null;
            } else {
                return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
            }
        }
    }
    /********判断H5页面是否在web容器中 start*********/

    export default {
        components: {
            [NavBar.name]: NavBar,
        },
        name: "rx-navBar",
        props: {
            //左侧按钮是否需要返回
            NoleftArrow:{
                type: Boolean,
                value: false,
            },
            //是否重写返回按钮
            backProxy: {
                type: Boolean,
                value: false,
            },
            //标题
            title: {
                type: String,
                default: ''
            },
            //返回多级页面，并传入几级
            returnPageNumber: {
                type: String,
                value: ''
            },
            //返回路径
            returnPage: {
                type: String,
                value: ''
            },
            //返回路径 参数
            params:{
                type: Object,
                value: ''
            },
            //是否是与android、IOS直接交互的页面
            androidOrIOSFlag:{
                type: String,
                value: '',
            },

        },
        data() {
            return {

            }
        },
        methods:{
            /**
             * 返回的点击事件
             */
            _onBackTap() {
                var returnPageNumber = this.returnPageNumber;
                var returnPage = this.returnPage;
                var params = this.params;

                if (!this.backProxy) {
                    if(this.androidOrIOSFlag == 'true'){
                        if(openInWebview()){
                            //APP传值*******begin*********
                            if(checkAndroid()){
                                console.log("----checkAndroid()-=true")
                                backToAndroid();
                            }else if(checkIOS()){
                                console.log("----checkAndroid()-=true")
                                window.webkit.messageHandlers.closeWindow.postMessage({});
                                // console.log("对ios传参，调用IOS关闭页面方法,zheshi");
                            }
                            //APP传值*******end*********
                        }
                        //清除本地localStorage缓存
                        localStorage.removeItem('currentLabel');
                        localStorage.removeItem('type');
                        localStorage.removeItem('inputValue');
                        localStorage.removeItem('currentLabelPublic');
                    }

                    if (returnPageNumber != '' && returnPageNumber != undefined) {
                        this.$router.go(parseInt(returnPageNumber));
                        return
                    }
                    if (returnPage != "" && returnPage != undefined) {
                        if(params == "" || params == undefined){
                            this.$router.replace({name: returnPage})
                        }else{
                            this.$router.replace({name:returnPage,params:params});
                        }
                        return;
                    }

                    this.$router.go(-1);
                    return
                }

                this.$emit('NaviBack')
            },
        }
    }
</script>

<style lang="less" scoped>

</style>